import "./App.css";

// @ts-ignore
import Chibi from "./assets/sad_finger_touch_chibi.png";

import styled from "styled-components";


const RootContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
});

const TitleHeader = styled.h1({
	marginBottom: 8,
});

const ImageChibi = styled.img({
	aspectRatio: 1,
	width: 200,
});

const SocialLinksContainer = styled.div({
display:'flex',
	flexDirection: 'column',
	alignItems: 'center',
	fontSize: 24,
	fontStyle: 'normal'
});

const ButtonSocial = styled.a({
	display:'flex',
	textDecoration: 'none',
	textTransform: 'uppercase',
  color: 'palevioletred',
  fontSize: '1em',
  marginBottom: '0.5em',
  padding: '0.25em 1em',
  border: '2px solid palevioletred',
  borderRadius: '3px',
	minWidth: '5em',
	justifyContent: 'center',

	"&:hover": {
		textDecoration: 'underline',
	},
});

const SubHeading = styled.h2({
	fontWeight: 500,
	marginBottom: 8,
	color: 'darkred',
});

const DividerLine = styled.div({
	width: '24vw',
	borderBottom: '1.5px solid palevioletred', 
	opacity: 0.6,
	marginTop: 16,
}); 

const GithubLinkElement = styled.a({
	color: 'palevioletred',
	marginBottom: 6,
}); 

const GraveyardLink = styled.a({
	color: 'palevioletred',
	marginBottom: 22,
	textDecoration: 'none',
	textTransform: 'uppercase',

	"&:hover": {
		textDecoration: 'underline',
	},
}); 

const LinkEmail = styled.a({
	display:'flex',
	textDecoration: 'none',
	textTransform: 'uppercase',
	color: 'palevioletred',
	fontSize: '1em',
	borderRadius: '3px',
	minWidth: '5em',
	justifyContent: 'center',
	"&:hover": {
		textDecoration: 'underline',
	},
	marginBottom: 8,
});


function GithubLink(props: {
	repoName: string;
}){
	return (
		<GithubLinkElement
			href={`https://github.com/dominicstop/${props.repoName}`}
			target="_blank"
		>
			{props.repoName}
		</GithubLinkElement>
	);
};


export default function App() {
	return (
		<RootContainer>
			<ImageChibi 
				src={Chibi} 
				alt={"Avatar"}
			/>
			<TitleHeader>
				🚧 Work in Progress 🚧
			</TitleHeader>
			<LinkEmail
				href={'mailto:dominic@dominicgo.dev'}
			>
				dominic@dominicgo.dev
			</LinkEmail>
			<GraveyardLink
				href={"https://drive.google.com/drive/u/1/folders/1qYUYJZqZWaSw7IjpD_kxKfbXieqARnKX"}
				target="_blank"
			>
				{'Project Archive/Graveyard'}
			</GraveyardLink>
			<SocialLinksContainer>
				<ButtonSocial 
					href={"https://github.com/dominicstop"}
					target="_blank"
				>
					Github
				</ButtonSocial>
				<ButtonSocial 
					href={"https://twitter.com/GoDominic"}
					target="_blank"
				>
					Twitter
				</ButtonSocial>
			</SocialLinksContainer>
			<DividerLine/>
			<SubHeading>
				OSS Projects
			</SubHeading>
			<GithubLink
				repoName="react-native-ios-context-menu"
			/>
			<GithubLink
				repoName="react-native-ios-modal"
			/>
			<GithubLink
				repoName="react-native-ios-adaptive-modal"
			/>
			<GithubLink
				repoName="react-native-ios-list-view"
			/>
			<GithubLink
				repoName="react-native-ios-popover"
			/>
		</RootContainer>
	);
};